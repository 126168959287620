.language-dropdown{
    width: 100%;
}

.language-loader{
    position: absolute;
    padding: 4px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
}

.language-selection {
    position: relative;
}