.snackbar {
    position: absolute;
    width: 22vw;
    top: 8rem;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 100;
  }
  
  .snackbar-alert {
    min-width: calc(min(80vw, 12.5rem));
    max-width: 80vw;
    text-align: center;
  }
  