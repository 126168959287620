  .captions-section {
    width: 28%;
    border-left: 1px solid rgba(82, 82, 82, 1);

  }
   
  .app-header {
    margin: 0.8rem 0 1rem 0;
    padding: 0 0.8rem 0.5rem 0.8rem;
    border-bottom: 1px rgba(0,0,0,1) solid;
  }
   
  .live-captions {
    padding: 0 0.8rem 0 0.8rem;
    height: calc(100% - (6.5rem)) !important;
  }
   
  .app-footer{
    margin: 0.5rem;
  }